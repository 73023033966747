import { Box, Center, Spinner } from "@chakra-ui/react";
import { useAuth } from "@the-volte/svc-core-sdk";
import Router, { useRouter } from "next/router";
import queryString from "query-string";
import React, { useEffect, useState } from "react";

import { ssrMode } from "@app/constants";
import { Loader, MainBodyWrapper } from "@components/atoms";
import { Footer } from "@components/organisms";
import RedirectedFromBanner from "@components/organisms/RedirectedFromBanner";
import { SaleBanner } from "@components/organisms/SaleBanner";
import { UnconfirmedOrderBanner } from "@components/organisms/UnconfirmedOrderBanner";
import { useLocalStorage } from "@hooks/useLocalStorage";
import useLogin from "@hooks/useLogin";
import { ShopConfig } from "@utils/ssr";

import {
  MainMenu,
  MetaConsumer,
  OverlayManager,
  OverlayProvider,
} from "../components/legacy";
import MainMenuProvider from "../components/legacy/MainMenuProvider";
import ShopProvider from "../components/legacy/ShopProvider";
import Notifications from "./Notifications";

type AppProps = ShopConfig;

const App: React.FC<AppProps> = ({ mainMenu, shopConfig, children, shop }) => {
  const { pathname, query } = useRouter();
  const { redirectedFrom } = query;

  const { user, externalLogout, loginByExternalPlugin, verifyLogin } =
    useAuth();
  const [totalUnconfirmedOrder, setTotalUnconfirmedOrder] = useState<number>(
    user?.lenderOrders?.totalCount || 0
  );
  const [loading, setLoading] = useState(false);

  const { externalAuthPlugin, login } = useLogin();

  const [authPlugin, setAuthPlugin] = useLocalStorage<string>(
    "authPlugin",
    undefined
  );

  const { location } = ssrMode ? global : window;

  const handleExternalAuthentication = async () => {
    const { code, state } = queryString.parse(location.search);
    let result;
    try {
      result = await loginByExternalPlugin({ code, state });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }
    if (result) {
      setAuthPlugin(externalAuthPlugin);
    } else {
      setAuthPlugin(undefined);
    }
    Router.push(location.pathname);
    setLoading(false);
  };
  useEffect(() => {
    if (authPlugin && !user) {
      setLoading(true);
      setAuthPlugin(undefined);
      const verifyUserLogin = async () => {
        const result = await verifyLogin();
        setLoading(false);
        if (!result) {
          setAuthPlugin(undefined); // user logged out - session expired
        }
      };
      verifyUserLogin();
    }
  }, [authPlugin, user]);

  useEffect(() => {
    const qs = queryString.parse(location.search);
    if ("session_state" in qs) {
      setLoading(true);
      handleExternalAuthentication();
    }
  }, [location.search]);

  useEffect(() => {
    setTotalUnconfirmedOrder(user?.lenderOrders?.totalCount || 0);
  }, [user]);
  const handleSignOut = async () => {
    setAuthPlugin(undefined);
    const payload = {
      pluginId: externalAuthPlugin,
      input: JSON.stringify({ returnTo: location.href }),
    };
    const {
      data: {
        externalLogout: { logoutData },
      },
    } = await externalLogout(payload);
    /* eslint-disable-next-line @typescript-eslint/naming-convention */
    const { logout_url } = JSON.parse(logoutData);
    location.href = logout_url;
  };
  const isPopup = pathname.startsWith("/popups");

  if (loading || (authPlugin && !user)) {
    return (
      <Box h="100%">
        <Center h="100vh">
          <Spinner />
        </Center>
      </Box>
    );
  }
  return (
    <ShopProvider shopConfig={shopConfig} shop={shop}>
      <MainMenuProvider mainMenu={mainMenu}>
        <OverlayProvider pathname={pathname}>
          <MetaConsumer />
          {!isPopup && (
            <>
              <MainMenu
                handleSignOut={handleSignOut}
                login={login}
                loading={loading}
              />
              <SaleBanner
                overlay={pathname === "/"}
                message={shopConfig?.saleBannerMessage || ""}
              />
              {totalUnconfirmedOrder > 0 && (
                <UnconfirmedOrderBanner
                  total={totalUnconfirmedOrder}
                  overlay={pathname === "/"}
                  isSaleActive={!!shopConfig?.saleBannerMessage}
                />
              )}
              {redirectedFrom && (
                <RedirectedFromBanner redirectedFrom={redirectedFrom} />
              )}
            </>
          )}
          {loading ? (
            <Loader fullScreen />
          ) : (
            <MainBodyWrapper>{children}</MainBodyWrapper>
          )}
          {!isPopup && (
            <>
              <Footer />
              <OverlayManager handleSignOut={handleSignOut} login={login} />
              <Notifications />
            </>
          )}
        </OverlayProvider>
      </MainMenuProvider>
    </ShopProvider>
  );
};

export default App;
