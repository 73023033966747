import React, { useState } from "react";

import { getMappedItems } from "@utils/menu";
import { ShopConfig } from "@utils/ssr";

import { defaultContext, MainMenuContext } from "./context";

type MainMenuProviderPops = { mainMenu: ShopConfig["mainMenu"] };

const MainMenuProvider: React.FC<MainMenuProviderPops> = ({
  mainMenu,
  children,
}) => {
  const [context] = useState<ShopConfig["mainMenu"]>({
    ...defaultContext,
    ...mainMenu,
    items: mainMenu?.items ? getMappedItems(mainMenu.items) : undefined,
  });

  return (
    <MainMenuContext.Provider value={context}>
      {children}
    </MainMenuContext.Provider>
  );
};

export default MainMenuProvider;
